import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import './App.css';
import Home from './pages/Home/Home'
import Navbar from './components/Navbar/Navbar'
import Projects from './pages/Projects/Projects'
import Tech from './pages/Tech/Tech'
import Contact from './pages/Contact/Contact'
import { useState } from 'react';
import Footer from './components/Footer/Footer';

function App() {
  const [isHome, setIsHome] = useState(false);
  return (
    <div className={isHome? 'App bg' : 'App'}>
      <BrowserRouter>
      <Navbar />
      <Switch>
        <Route exact path='/'>
          <Home setIsHome={setIsHome}/>
        </Route>
        <Route path='/projects'>
          <Projects />
        </Route>
        <Route path='/tech'>
          <Tech />
        </Route>
        <Route path='/contact'>
          <Contact />
        </Route>
      </Switch>
      {/* <Footer /> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
