import React from 'react'
import './Contact.css'

export default function Contact() {
  return (
    <div className='contact'>
      <p>contact page</p>
    </div>
  )
}
