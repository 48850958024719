import React from 'react'
import './Tech.css'

export default function Tech() {
  return (
    <div className='tech'>
      <p>Tech page</p>
    </div>
  )
}
