import React from "react";
import ProjectItem from "./ProjectItem";
import "./Projects.css";

let projects = [
    // github finder readme 사진추가+ youtube 주소 추가
    // react native readme 사진, 설명, Youtube 주소 추가
    // playr 데모 동영상 첨부하기
    { id: 7, src: "/img/kiyou.png", coding: undefined, website: "https://kiyou-eshop.netlify.app", title: "E-store for 20's and 30's women's clothing", explanation: "React.js Progressive Web Application (PWA) online clothing store website. Users can login with gmail account and add items to the cart. Admins can add products to the website.", languages: ["/img/reactjs.png", "/img/firebase.webp", "/img/tailwind.png", "/img/tanstack.png"] },
    { id: 0, src: "/img/test.png", coding: undefined, website: "https://www.algonquincollege.com/arie/2022/03/balanced-brain/", title: "Balanced Brain", explanation: "PWA to manage patients' therapy sessions for therapists. Patients can amass points for each healthy behavior and track their overall well-being scores with mixed charts", languages: ["/img/next.webp", "/img/firebase.webp", "img/chart.png", "/img/tailwind.png"] },
    { id: 6, src: "/img/youtube.png", coding: undefined, website: "https://gyuyoung-youtube-app.netlify.app/", title: "Youtube clone app", explanation: "Youtube clone website with React.js with Youtube Open API. Users can seamlessly browse videos across various categories and utilize a robust search feature, offering an immersive experience akin to the real YouTube platform.", languages: ["/img/reactjs.png", "/img/firebase.webp", "/img/tailwind.png"] },
    // { id: 7, src: "/img/kiyou2.png", coding: undefined, website: "https://kiyou-eshop.netlify.app", title: "E-store for women's clothing", explanation: "React.js PWA online store. Users can login with gmail account and add items to the cart. Admins can add products to the website.", languages: ["/img/reactjs.png", "/img/firebase.webp", "/img/tailwind.png", "/img/tanstack.png"] },

    { id: 1, src: "/img/1.jpg", coding: "https://github.com/lee00777/react_project_management_site.git", website: "https://awesome-darwin-e3b817.netlify.app/", title: "Project Manager", explanation: "Project management site built with React.js, Firebase, Firestore and MUI", languages: ["/img/reactjs.png", "/img/firebase.webp", "/img/js.png", "/img/mui.png", "/img/css3.png"] },
    { id: 3, src: "/img/react_native.jpg", coding: "https://github.com/lee00777/TvAndMovies.git", website: "https://youtu.be/oUU6da_SGmQ", title: "CheesePop TV Show", explanation: "React Native App for TV shows and movies (Partnership)", languages: ["/img/2222.png"] },

    // {id: 3, "src": "/img/react_native.jpg", coding:"https://github.com/lee00777/TvAndMovies.git",website:"https://expo.dev/@deje0014/react-native-final",title: "CheesePop TV Show", explanation: "React Native App for TV shows and movies (Partnership)", languages:["/img/2222.png"]},

    // { id: 4, src: "/img/giftr.jpg", coding: "https://github.com/lee00777/pwa-giftR.git", website: "https://lucid-mirzakhani-2db87a.netlify.app", title: "GiftR", explanation: "Mobile first Progressive Web Application to manage gift ideas built with JavaScript, Materialize and CSS3/HTML5 (Partnership)", languages: ["/img/pwa.png", "/img/js.png", "/img/materialize.png", "/img/css3.png", "/img/html5.jpeg"] },
    // { id: 5, src: "/img/giftr.jpg", coding: "https://github.com/lee00777/mad9124-w21-p1-giftr.git", website: undefined, title: "GiftR (API)", explanation: "API for Giftr built with Node.js, Express, MongoDB and Docker (Partnership)", languages: ["/img/node.png", "/img/express1.png", "/img/mongodb.jpeg", "/img/docker.png"] },
    { id: 2, src: "/img/react_movie1.png", coding: "https://github.com/lee00777/movieChart.git", website: "https://lee00777.github.io/movieChart", title: "Top Movies", explanation: "Movie site built with React.js, TMDB API", languages: ["/img/reactjs.png", "/img/css3.png"] },

    // {id: 10, "src": "/img/giftr.jpg", coding:"https://github.com/lee00777/mad9135-c4-react-next-1.git",website:"",title: "Popular Dog Breeds", explanation: "Next.js app with API and CRUD functionality built with Next.JS, Theme-UI and JavaScript (Partnership)", languages:["/img/next.webp", "/img/theme ui.png", "/img/js.png", "/img/css3.png"]},
    // {id: 7, "src": "/img/movie_vanillajs2.png", coding:"https://github.com/lee00777/react_project_management_site.git",website:"https://project-management-site-e15f2.web.app/login",title: "MovieR", explanation: "Single Page Application built with LocalStorage, History and TMDB API", languages:["/img/js.png", "/img/materialize.png", "/img/css3.png", "/img/html5.jpeg"]},
    // {id: 8, "src": "/img/movie_vanillajs2.png", coding:"https://github.com/lee00777/react_project_management_site.git",website:"https://project-management-site-e15f2.web.app/login",title: "MovieR", explanation: "Installable, Offline-first PWA built with IndexedDB, JavaScript, Materialize and HTML", languages:["/img/js.png", "/img/materialize.png", "/img/css3.png", "/img/html5.jpeg"]},
    // 이놈 할지 말지 고민하기..
    { id: 9, src: "/img/ios.png", title: "Github Finder", coding: "https://github.com/lee00777/githubFinder.git", website: "https://youtu.be/Bl7iA3u6S8Q", explanation: `iOS Native app to find Github users built with Swift`, languages: ["/img/swift.png"] },

    { id: 6, src: "/img/playr.jpg", coding: "https://github.com/lee00777/cordova-playr.git", website: undefined, title: "PlayR", explanation: "Cordova based music player mobile app (Android version, SPA) built with JavaScript, CSS3/HTML5", languages: ["/img/cordova.png", "/img/js.png", "/img/css3.png", "/img/html5.jpeg"] },
];

export default function Projects() {
    return (
        <div className="project-list">
            {projects.map((project) => {
                return <ProjectItem key={project.id} project={project} />;
            })}
        </div>
    );
}
