import React, { useEffect, useRef, useState } from 'react'
import Typewriter from "typewriter-effect";
import linkedInIcon from '../../assets/linkedin-logo.png'
import githubIcon from '../../assets/github-logo.png'

import './Home.css'
import { Link } from 'react-router-dom';


export default function Home({setIsHome}) {
  useEffect(()=>{
    setIsHome(true)
    return()=>{setIsHome(false)}
  },[])

  return (
    <div className='home'>
      <div className='introduction'>
        <Typewriter
          onInit={(typewriter) => {
            typewriter.typeString('<strong>Hi</strong> there,')
              .pauseFor(1000)
              .deleteAll()
              .typeString('<strong>Welcome</strong> to my website!')
              .pauseFor(2500)
              // .deleteAll()
              // .deleteChars(10)
              .callFunction(() => {
                console.log('All strings were deleted');
              })
              .start();
          }}
        />
        <p className='name'><span className='first-name'>GYUYOUNG</span> <strong>LEE</strong> </p>
        <p className='short-intro'> Web/App developer based in Canada  </p>
        {/* links */}
        <div className='links'>
          <a href="https://www.linkedin.com/in/gyuyoung-lee-1a087221b/">
            <img src={linkedInIcon} alt="linkedin logo"/>
          </a>
          <a href="https://github.com/lee00777">
            <img src={githubIcon} alt="github logo"/>
          </a>  
          <Link to='/projects'>
            <button className='btn'>
                VIEW MY WORK
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}
