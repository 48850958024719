import {Link} from 'react-router-dom'
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import HomeSharpIcon from '@mui/icons-material/HomeSharp';
import PhoneIcon from '@mui/icons-material/Phone';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import WorkIcon from '@mui/icons-material/Work';
import { useState } from 'react';
import './Navbar.css'

const drawerWidth = 240

const useStyles = makeStyles({
  root:{
    backgroundColor: "#282c34",
    color: "#f8fdff",
    textDecoration: 'none',
    margin: `0.2rem 1rem`,
    marginTop:"1.6rem"
    },
  drawer:{
    width:drawerWidth,
  },
  drawerPaper:{
    width:drawerWidth
  },
  drawerItems:{
    textDecoration: 'none',
    color:"#fff",
    fontSize:'1rem',
  },
  appBar:{
    display:'flex',
    marginLeft:'auto',
    gap:'4rem',
    fontSize:'1.5rem'
  },
  mainNav:{
    width:'50vw',
    marginLeft:'1rem',
    fontSize:"1.2rem",
  },
  divider:{
    margin:`0 10px`,

  },
  welcome: {
    fontSize:"1rem",
    letterSpacing:"0.2rem",
    border:"2px solid",
    borderImageSlice:1,
    borderWidth:3,
    borderImageSource:" linear-gradient(to left, #f0f0f0, #aee1f9)",
    padding:"0.8rem",
  },
  
})

export default function ResponsiveDrawer (props) {
  const classes = useStyles()
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false)
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const drawer = (
    <div >
      <List className="drawer">
        <ListItem button key="home" className="drawerList" sx={{height:"4rem", borderBottom:"1px solid #999"}}>
          <ListItemIcon>
            <HomeSharpIcon sx={{color:"#fff"}}/>
          </ListItemIcon>
          <Link to="/" className={classes.drawerItems}> <ListItemText primary="Home" /> </Link>
        </ListItem>
        <ListItem button key="add" sx={{height:"4rem", borderBottom:"1px solid #999"}}>
          <ListItemIcon>
            <WorkIcon sx={{color:"#fff"}}/>
          </ListItemIcon>
          <Link to="/projects" className={classes.drawerItems}> <ListItemText primary="Projects" /></Link>
        </ListItem>
        {/* <ListItem button key="tech" sx={{height:"4rem", borderBottom:"1px solid #999"}}>
          <ListItemIcon>
            <LightbulbIcon sx={{color:"#fff"}}/>
          </ListItemIcon>
          <Link to="/tech" className={classes.drawerItems}> <ListItemText primary="Technology" /></Link>
        </ListItem>
        <ListItem button key="contact" sx={{height:"4rem", borderBottom:"1px solid #999"}}>
          <ListItemIcon>
            <PhoneIcon sx={{color:"#fff"}}/>
          </ListItemIcon>
          <Link to="/contact" className={classes.drawerItems}> <ListItemText primary="Contact" /></Link>
        </ListItem> */}
        <Divider className={classes.divider}/>
      </List>
    </div>
  );
  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <Box sx={{ display: 'flex'}}>
    <CssBaseline/>
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        width: { md:`100vw`},
        height:'5.2rem',
        background:"#282c34",
        borderBottom:"1px solid #fff",
        // padding:"2rem",
        display:"flex",
        // justifyContent:"center"
      }}
    >
      <Toolbar sx={{  marginBottom:"1rem"}}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 1, ml:1, display: { sm: 'none' }, marginTop:{xs:'1.2rem'}}}>
          <MenuIcon />
        </IconButton>
        <Link to="/" className={classes.root}>
          <Typography variant="p" className={classes.welcome}> GYUYOUNG </Typography>
        </Link>
        <Box className={classes.appBar} >
          <Link to="/projects" className={classes.root}>
            <Typography variant="p" className="links" component="p" color= "rgb(111, 174, 251)" fontSize="1.3rem" sx={{ display: { xs: 'none', sm: 'block' }}}> 
              Projects
            </Typography>       
          </Link>
          {/* <Link to="/tech" className={classes.root}>
            <Typography variant="p" className="links" component="p" color= "rgb(111, 174, 251)" fontSize="1.3rem" sx={{ display: { xs: 'none', sm: 'block' }}}> 
              Tech
            </Typography>       
          </Link>
          <Link to="/contact" className={classes.root}>
            <Typography variant="p" className="links" component="p" color= "rgb(111, 174, 251)" fontSize="1.3rem" sx={{ display: { xs: 'none', sm: 'block' }}}> 
              Contact
            </Typography>       
          </Link> */}
        </Box>
      </Toolbar>
      <hr />
    </AppBar>
    {/* drawer */}
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders">
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, 
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}>
        {drawer}
      </Drawer>
    </Box>
    </Box>
  );
};
