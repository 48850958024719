import React from "react";
import "./Projects.css";
// import { FcLikePlaceholder, FcLike } from "react-icons/fc";
// import { FiVideo } from "react-icons/fi";
// import Numeral from "numeral";
import VideocamIcon from "@mui/icons-material/Videocam";
import CodeIcon from "@mui/icons-material/Code";
import { Link } from "react-router-dom";

export default function ProjectItem({ project }) {
  return (
    <div className="cards">
      <div>
        <a href={project.website}>
          <img className="cards-image" src={project.src} alt="Project screenshots" />
          <div className="cards-text">
            <h2>{project.title}</h2>
            <p>{project.explanation}</p>
            <h3> Technology </h3>
            <div className="logos">
              {project.languages.map((language) => {
                return <img src={language} key={project.id} alt="logo" className="logo" />;
              })}
            </div>
          </div>
        </a>
      </div>
      <div className="cards-stats">
        <div className="stat">
          <a href={project.website === "undefined" ? "https://www.gyuyoung.com/projects" : project.website} className="value">
            <div className="type">
              {project.website === undefined && <p>N/A</p>}
              {project.website !== undefined && (
                <>
                  <VideocamIcon className="icon" /> <p>Demo</p>
                </>
              )}
            </div>
          </a>
        </div>
        <div className="stat">
          <a href={project.coding === "undefined" ? "https://www.gyuyoung.com/projects" : project.coding} className="value">
            <div className="type">
              <CodeIcon className="icon" />
              {project.coding === undefined && <p className="blockClick">N/A</p>}
              {project.coding !== undefined && (
                <>
                  <p>Codes</p>
                </>
              )}
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
